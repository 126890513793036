import types from './types';
import Api from 'library/api';
import React from 'react';

/**
 * Toggle hamburger menu
 *
 * @return {void}
 */
export const toggleNav = toggled => ({
  type: types.TOGGLE_NAV,
  toggled,
});

/**
 * Toggle login modal
 *
 * @return {void}
 */
export const toggleLoginModal = toggled => ({
  type: types.TOGGLE_LOGIN_MODAL,
  toggled,
});

/**
 * Toggle login modal
 *
 * @return {void}
 */
export const setLoginModalPage = page => ({
  type: types.SET_LOGIN_MODAL_PAGE,
  page,
});

export const setLoginModalMessage = page => ({
  type: types.SET_LOGIN_MODAL_MESSAGE,
  page,
});

/**
 * Update window width on window resize
 *
 * @return {void}
 */
export const updateWindowWidth = windowWidth => ({
  type: types.UPDATE_WINDOW_WIDTH,
  windowWidth,
});
/**
 * Update window height on window resize
 *
 * @return {void}
 */
export const updateWindowHeight = windowHeight => ({
  type: types.UPDATE_WINDOW_HEIGHT,
  windowHeight,
});

/**
 * Toggle toast
 *
 * @return {void}
 */
export const toggleToast = (
  toggled,
  text: string | React.ReactNode = '',
  toastType = 'default',
  icon = '',
  placement = 'bottom'
) => ({
  type: types.TOGGLE_TOAST,
  toggled,
  text,
  toastType,
  icon,
  placement,
});

/**
 * Toggle calendly error
 *
 * @return {void}
 */
export const toggleCalendlyError = message => ({
  type: types.TOGGLE_CALENDLY_ERROR,
  payload: message,
});

/**
 * Fetch all categories
 *
 * @return {Array<Record<string, string>>}
 */
export const getAllCategories = () => ({
  type: types.GET_ALL_CATEGORIES,
  promise: Api.get('/categories'),
});

/**
 * Toggle leave session creator modal
 *
 * @return {void}
 */

export const toggleExitSessionCreatorModal = (toggled, newPath) => ({
  type: types.TOGGLE_EXIT_SESSION_CREATOR_MODAL,
  toggled,
  newPath,
});
