import keyMirror from 'keymirror';

const types = keyMirror({
  TOGGLE_NAV: null,
  TOGGLE_LOGIN_MODAL: null,
  UPDATE_WINDOW_WIDTH: null,
  UPDATE_WINDOW_HEIGHT: null,
  SET_LOGIN_MODAL_PAGE: null,
  SET_LOGIN_MODAL_MESSAGE: null,
  GET_ALL_CATEGORIES: null,
  GET_ALL_CATEGORIES_SUCCESS: null,
  GET_ALL_CATEGORIES_FAILURE: null,
  TOGGLE_TOAST: null,
  TOGGLE_EXIT_SESSION_CREATOR_MODAL: null,
  TOGGLE_CALENDLY_ERROR: null,
});

export default types;
